<template>
  <tm-popup
    :submit="onSubmit"
    ref="popup"
    :title="title"
  >
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الاسم"
          placeholder="الاسم"
          type="text"
          v-model="program.name"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الوصف"
          placeholder="الوصف"
          type="text"
          v-model="program.description"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-select
          :options="statusOptions"
          :reduce="name => name.value"
          class="w-full"
          label="name"
          name="حالة البرنامج"
          v-model="program.status"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الموقع"
          placeholder="الموقع"
          type="text"
          v-model="program.location"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="عدد المستفيدين"
          placeholder="عدد المستفيدين"
          type="number"
          v-model="program.beneficiariesNumber"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ الكلي"
          placeholder="المبلغ الكلي"
          v-model="program.targetAmount"
          validation-rules="required"
          type="number"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="المبلغ المجموع"
          placeholder="المبلغ المجموع"
          type="number"
          v-model="program.collectedAmount"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="ترتيب البرنامج في الموقع"
          placeholder="ترتيب البرنامج في الموقع"
          type="number"
          v-model="program.order"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="تاريخ الانتهاء "
          placeholder="تاريخ الانتهاء"
          type="datetime-local"
          v-model="program.finishedAt"
          validation-rules="required"
        />
      </div>
    </div>
    <div class="vx-row">
      <div class="vx-col w-full mb-2">
        <tm-input
          class="w-full"
          name="الأنشطة والأعمال السابقة"
          placeholder="أضف رابط فيديو"
          type="text"
          v-model="previousActivity"
          validation-rules=""
        />
      </div>
    </div>
    <vs-col
      class="mb-6 mt-2"
      vs-lg="12"
    >
      <vs-button
        @click="addPreviousActivity()"
        color="primary"
        icon="icon-plus"
        icon-pack="feather"
        size="small"
        type="filled"
      >
        إضافة رابط فيديو
      </vs-button>
    </vs-col>
    <div
      v-if="program.previousActivities.length > 0"
      class="vx-row"
    >
      <div class="vx-col w-full mb-2">
        <div
          v-for="(item,index) in program.previousActivities"
          :key="index"
        >
          <vs-row vs-align="right">
            <vs-col
              vs-type="flex"
              vs-justify="start"
              vs-align="right"
              vs-lg="10"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-input
                placeholder=""
                name="title"
                disabled
                color="#5E445A"
                class="w-full mt-3"
                v-model="program.previousActivities[index]"
              />
            </vs-col>
            <vs-col
              class="mt-4 ml-5"
              vs-type="flex"
              vs-justify="end"
              vs-align="left"
              vs-lg="1"
              vs-sm="4"
              vs-xs="12"
            >
              <vs-button
                @click="deletePreviousctivity(index)"
                color="danger"
                icon="icon-x"
                icon-pack="feather"
                size="small"
                type="gradient"
              />
            </vs-col>
          </vs-row>
        </div>
      </div>
    </div>
    <div class="vx-row mi-format-align-justify mb-5">
      <div class="vx-col w-full">
        <h4 class="my-5">
          الصور
        </h4>
        <file-pond-component
          :files="program.images.map(image => (image))"
          :multiple="true"
          @fileDeleted="(fileIndex) => fileDeleted(fileIndex)"
          @fileUploaded="(files) => fileUploaded(files)"
          project="websiteDonation"
          ref="fileUpload"
        />
      </div>
    </div>
  </tm-popup>
</template>

<script>
import FilePondComponent from "@/app/shared/shared-components/FilePondComponent.vue";
import TmInput from "@/app/shared/shared-components/TmInput.vue";
import TmPopup from "@/app/shared/shared-components/TmPopup.vue";
import TmSelect from "@/app/shared/shared-components/TmSelect.vue";
import utilities from "@/app/shared/utilities";
import toasted from "@/app/shared/utilities/toasted";
import {RepositoryFactory} from "../../donations-repositories/donationsRepositoryFactory";

const programsRepo = RepositoryFactory.get("programsRepository");

export default {
  name: "ProgramPopup",
  data() {
    return {
      programs: [],
      parentProgram: '',
      previousActivity: '',
      statusOptions: [
        {
          name: 'مفتوح',
          value: 'OPENED'
        },
        {
          name: 'مغلق',
          value: 'CLOSED'
        }
      ],
    };
  },
  components: {
    TmPopup,
    TmInput,
    TmSelect,
    FilePondComponent
  },
  props: {
    program: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      required: true
    },
  },
  methods: {
    async onSubmit() {
      if (utilities.checkTargetAmount(this.program.targetAmount, this.program.collectedAmount)) {
        this.$refs.popup.endLoading();
        toasted.failed('المبلغ الكلي يجب أن يكون أكبر أو يساوي المبلغ المجموع');
      } else {
        if (this.program.id == null)
          this.create();
        else
          this.update();
      }
    },
    addPreviousActivity() {
      this.program.previousActivities.push(this.previousActivity);
    },
    deletePreviousctivity(index) {
      this.program.previousActivities.splice(index, 1);
    },
    initProgramToRequest() {
      this.program.collectedAmount = parseInt(this.program.collectedAmount);
      this.program.beneficiariesNumber = parseInt(this.program.beneficiariesNumber);
      this.program.targetAmount = parseInt(this.program.targetAmount);
      this.program.order = parseInt(this.program.order);
    },
    async create() {
      this.initProgramToRequest();
      let response = await programsRepo.saveProgram(this.program);
      this.$refs.popup.close();
      this.$emit("created", response);
    },
    async update() {
      this.initProgramToRequest();
      let response = await programsRepo.updateProgram(this.program);
      this.$refs.popup.close();
      this.$emit("updated", response);
    },
    fileUploaded(files) {
      this.program.images.push(...files.map(file => (file)));
    },
    fileDeleted(imageIndex) {
      this.program.images.splice(imageIndex, 1);
    },
    async fetchAllPrograms() {
      this.programs = await programsRepo.fetchAllPrograms();
    },
    open() {
      if (this.program.id != null)
        this.program.finishedAt = this.program.finishedAt.split('.')[0];
      this.fetchAllPrograms();
      this.previousActivity = '';
      this.$refs.popup.open();
    },
  },
};
</script>

<style scoped>

</style>
